import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class BulletinsRepository extends BaseRepository {
    baseUrl = 'management/specifications/bulletins';

    /**
     * Fetches the last bulletin revision number
     *
     * @param bulletinId
     * @return {*}
     */
    nextBulletinRevisionNumber(bulletinId) {
        return this.httpClient.get(`${this.baseUrl}/${bulletinId}/next-revision-number`);
    }

    /**
     * Fetches the last bulletin revision number
     *
     * @param bulletinId
     * @return {*}
     */
    lastBulletinRevisionModel(bulletinId) {
        return this.httpClient.get(`${this.baseUrl}/${bulletinId}/last-revision-model`);
    }

    /**
     * Fetches the next bulletin revision number
     *
     * @return {*}
     */
    nextBulletinRevisionModel() {
        return this.httpClient.get(`${this.baseUrl}/next-revision-model`);
    }

    /**
     * Returns all records in 'test_code - name - norm' format
     *
     * @returns {*}
     */
    allWithCode() {
        return this.httpClient.get(`${this.baseUrl}/all-with-code`);
    }

    /**
     * search listings by params
     *
     * @param bulletinId
     * @param criteria
     * @return {*}
     */
    searchListings(bulletinId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${bulletinId}/listings`, criteria);
    }

    /**
     * search specifications by params
     *
     * @param bulletinId
     * @param criteria
     * @return {*}
     */
    searchSpecifications(bulletinId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${bulletinId}/specifications`, criteria);
    }
}
